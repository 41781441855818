<template>
  <div class="wrap">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="bread">
      <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/personalcenter/Cart' }">Shopping Cart</el-breadcrumb-item>
      <el-breadcrumb-item>Place Order</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="tit">Place Order</div>
    <div style="display: flex;margin-bottom: 30px;">
      <div class="lf">
        <div class="info">
          <div class="infotit">
            <div style="display: flex;justify-content: space-between;">
              <div>Personal Info</div>
              <div @click="enterEditMode" v-if="!editMode"
                style="margin-right: 36px;cursor: pointer;font-size: 14px;color: #4583E1;font-weight: normal;">
                <i class="el-icon-edit-outline"></i><span style="text-decoration: underline;"> Edit</span>
              </div>
            </div>
          </div>
          <div style="padding: 25px 32px;">
            <div style="display: flex;">
              <div style="flex: 1;">
                <div class="infotits">UserName</div>
                <div v-if="!editMode" class="infodetails">{{ personalInfo.nickName }}</div>
                <el-form v-else ref="personalInfo" :model="personalInfo">
                  <el-form-item prop="nickName" style="width: 258px;" :rules="[
                    { required: true, message: 'Please enter your nickName', trigger: 'blur' },
                  ]">
                    <el-input v-model="personalInfo.nickName"></el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div style="flex: 1;">
                <div class="infotits">Company</div>
                <div class="infodetails">{{ personalInfo.companyName }}</div>
              </div>
            </div>
            <div style="display: flex;margin-top: 17px;">
              <div style="flex: 1;">
                <div class="infotits">Email</div>
                <div v-if="!editMode" class="infodetails">{{ personalInfo.email }}</div>
                <el-form v-else ref="personalInfo" :model="personalInfo">
                  <el-form-item prop="email" style="width: 258px;" :rules="[
                    { required: true, message: 'Please enter your email address', trigger: 'blur' },
                    { type: 'email', message: 'Please enter the correct email address', trigger: ['blur', 'change'] }
                  ]">
                    <el-input v-model="personalInfo.email"></el-input>
                  </el-form-item>
                </el-form>
              </div>
              <div style="flex: 1;">
                <div class="infotits">Phone Number</div>
                <div v-if="!editMode" class="infodetails">{{ personalInfo.phone }}</div>
                <el-form v-else ref="personalInfo" :model="personalInfo">
                  <el-form-item prop="phone" style="width: 258px;" :rules="[
                    { required: true, message: 'Please enter your phone', trigger: 'blur' },
                  ]">
                    <el-input v-model="personalInfo.phone"></el-input>
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div v-if="editMode" style="display: flex;justify-content: right;">
              <button @click="submitForm('personalInfo')" class="save">Save</button>
            </div>
          </div>
        </div>
        <div class="info1" style="margin-top: 30px;">
          <div class="infotit">
            Shipping details
          </div>
          <div style="padding: 25px 16px 0;">
            <div :class="{ 'grayed-out': isGrayedOut }">
              <div style="margin-left: 16px;display: flex;justify-content: space-between;align-items: center;">
                <div style="font-size: 12px;color: #959595;">Please select a shipping address</div>
                <div @click="handleButtonClick" style="cursor: pointer;">
                  <img src="../assets/imgs/uploads/Group 391.png" alt="" style="width: 15px;height: 15px;">
                  <span class="add">Add New Address</span>
                </div>
              </div>
              <div class="addlist">
                <div :class="{ 'addlist-out': isGrayedOut }" style="height: 373px;overflow: hidden;">
                  <div class="scrollable-content">
                    <div v-for="(item, index) in addresses" :key="index" class="adds">
                      <div>
                        <input type="radio" :value="item.id" v-model="selectedItem" />
                      </div>
                      <div style="width: 313px;margin-left: 13px;">
                        <p>{{ item.nickName }}</p>
                        <p style="margin-top: 8px;">{{ item.address }}{{ item.addressTwo }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-left: 5px;" v-if="showOtherArea">
              <el-form :model="address" :hide-required-asterisk="true" ref="address">
                <div>
                  <p style="font-size: 14px; margin: 17px 0 6px;">Country/Region</p>
                  <el-form-item prop="country" :rules="[
                    { required: true, message: 'country cannot be empty' },
                  ]">
                    <el-input v-model="address.country" style="width: 610px;" :validate-event="false"
                      placeholder="Please Select your country/region"></el-input>
                  </el-form-item>
                </div>
                <div>
                  <p style="font-size: 14px; margin: 17px 0 6px;">City</p>
                  <el-form-item prop="city" :rules="[
                    { required: true, message: 'city cannot be empty' },
                  ]">
                    <el-input v-model="address.city" style="width: 610px;" :validate-event="false"></el-input>
                  </el-form-item>
                </div>
                <div>
                  <p style="font-size: 14px; margin: 17px 0 6px;">Address1</p>
                  <el-form-item prop="address" :rules="[
                    { required: true, message: 'address1 cannot be empty' },
                  ]">
                    <el-input v-model="address.address" style="width: 610px;" :validate-event="false"
                      placeholder="Street address, company name"></el-input>
                  </el-form-item>
                </div>
                <div>
                  <p style="font-size: 14px; margin: 17px 0 6px;">Address2</p>
                  <el-form-item prop="addressTwo">
                    <el-input v-model="address.addressTwo" style="width: 610px;" :validate-event="false"
                      placeholder="Apartment, Suite, Unit, Building, Floor ect (optional)"></el-input>
                  </el-form-item>
                </div>
                <div style="display: flex;align-items: center;">
                  <div>
                    <p style="font-size: 14px; margin: 17px 0 6px;">ZIP Code</p>
                    <el-form-item prop="zipCode">
                      <el-input v-model="address.zipCode" style="width: 295px;" :validate-event="false"
                        placeholder="Or post code"></el-input>
                    </el-form-item>
                  </div>
                  <div style="margin-left: 18px;">
                    <p style="font-size: 14px; margin: 17px 0 6px;">Contact Number</p>
                    <el-form-item prop="contactNumber">
                      <el-input v-model="address.contactNumber" style="width: 295px;" :validate-event="false"
                        placeholder="Contact number"></el-input>
                    </el-form-item>
                  </div>
                </div>
                <el-checkbox v-model="checked">Save this shipping infomation for next time</el-checkbox>
                <el-form-item style="margin: 20px 10px 0 0;">
                  <div style="display: flex;justify-content: space-between;">
                    <el-button type="primary" @click="backadd">Back</el-button>
                    <el-button @click="submitForm1('address')" class="addnew">Save New Address</el-button>
                  </div>
                </el-form-item>
              </el-form>
            </div>
          </div>

        </div>
      </div>
      <div class="rig">
        <div style="font-size: 18px;font-weight: bold;padding-bottom: 10px;border-bottom: 1px solid #EAEAEA;">Shopping
          Cart</div>
        <div style="overflow: hidden;">
          <div style="overflow: auto;max-height: 36.458vw;">
            <div v-for="item in getCheckGoodList" :key="item.id">
              <el-row style="margin: 20px 0 18px;border-bottom: 1px solid #EAEAEA;padding-bottom: 15px;">
                <el-col :span="5">
                  <img style=" height: 77px; width: 77px; border-radius: 5px; border: 1px solid #E4E4E4;"
                    v-lazy="item.imageUrl" />
                </el-col>
                <el-col :span="19">
                  <div style="display: flex;">
                    <div style="color: #000;font-weight: bold;margin-bottom: 7px;">{{ item.skuName }} </div>
                    <div
                      style="width: 65px;height: 20px;margin-left: 10px; line-height: 20px;font-size: 10px;border: 1px solid #333;border-radius: 9.5px;display: flex;justify-content: center;">
                      {{ getLabelForStatus(item.proType) }}
                    </div>
                  </div>
                  <div>
                    <div><span style="color: #959595;font-size: 14px;margin-top: 7px;">UnitPrice： $ {{ item.price
                    }}</span></div>
                    <div style="display: flex;justify-content: space-between;">
                      <div>Quantity：<span style="color: #000;font-weight: bold;">{{ item.num }}</span></div>
                      <div><span style="color: #000;font-weight: bold;">${{ (item.price * 1000) * item.num / 1000
                      }}</span>
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>

            </div>
          </div>
        </div>


        <div style="height: 22%; padding:10px; font-size: 16px;">
          <table style="width: 100%;color: #A2A2A2" cellpadding="5">
            <tr>
              <td colspan="2" style="color: black; font-weight: bold; font-size: 18px">Price Details</td>
            </tr>
            <tr>
              <td>Subtotal</td>
              <td style="text-align: right">${{ getTotalPrice }}</td>
            </tr>
            <tr>
              <td colspan="2"><el-button :disabled="isClick" type="primary" style="width: 100%" @click="addOrder()">PLACE
                  ORDER</el-button>
              </td>
            </tr>
            <tr>
              <td colspan="2" style="font-size: 16px;color: #333;font-weight: bold;cursor: pointer;">
                <div @click="goBack" style="display: flex;justify-content: center;">&lt; Back to Cart</div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      personalInfo: '',
      selectedItem: null,
      addresses: [],
      address: {
        country: '',
        city: '',
        address: '',
        addressTwo: '',
        zipCode: '200000',
        contactNumber: '',
        nickName: '',
        status: '',
        umsId: this.$store.getters.getUser.id
      },
      isGrayedOut: false,
      showOtherArea: false,
      isClick: false,
      editMode: false,
      checked: false
    }
  },
  activated() {
    this.getpersonInfo()
    this.activated1()
    this.defaultadd()
  },
  methods: {
    ...mapActions(["updateShoppingCart", "deleteShoppingCart", "checkAll"]),
    getpersonInfo() {
      this.$axios
        .post("/api/mall/customer/personInfo", {
          id: this.$store.getters.getUser.id
        })
        .then(res => {
          if (res.data.code === 200) {
            this.personalInfo = res.data.data
          }
        })
        .catch(err => {
          return Promise.reject(err);
        });
    },
    defaultadd() {
      this.$axios
        .post("/api/mall/customer/getDefaultAddress", {
          userId: this.$store.getters.getUser.id,
        })
        .then(res => {
          if (res.data.code === 200) {
            if (res.data.data) {
              this.selectedItem = res.data.data.addressId
            }
          }
        })
        .catch(err => {
          return Promise.reject(err);
        });
    },
    activated1() {
      this.$axios
        .post("/api/mall/customer/getAddress", {
          umsId: this.$store.getters.getUser.id
        })
        .then(res => {
          if (res.data.code === 200) {
            this.addresses = []
            this.addresses = res.data.data
          }
        })
        .catch(err => {
          return Promise.reject(err);
        });
    },
    enterEditMode() {
      this.editMode = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.editMode = false;
          this.$axios
            .post("/api/mall/customer/saveInfo", this.personalInfo)
          this.$message({
            message: 'Modified successfully',
            type: "success",
          });
        } else {
          return false;
        }
      });
    },
    submitForm1(formName1) {
      if (this.checked === true) {
        this.address.status = 0
      }
      this.$refs[formName1].validate((valid) => {
        if (valid) {
          this.$axios
            .post("/api/mall/customer/saveAddress", this.address)
            .then(res => {
              if (res.data.code === 200) {
                this.activated1()
                this.$message({
                  message: 'Add successfully',
                  type: "success",
                });
                this.address = {}
                this.address.umsId = this.$store.getters.getUser.id
                this.isGrayedOut = false;
                this.showOtherArea = false;
                this.checked = false;
                this.defaultadd()
              }
            })
            .catch(err => {
              return Promise.reject(err);
            });
        } else {
          return false;
        }
      });
    },
    handleButtonClick() {
      this.isGrayedOut = true;
      this.showOtherArea = true;
    },
    backadd() {
      this.isGrayedOut = false;
      this.showOtherArea = false;
    },
    getLabelForStatus(status) {
      var a = status + ''
      switch (a) {
        case '0':
          return this.$t('list.instock');
        case '1':
          return this.$t('list.production');
        case '2':
          return this.$t('list.ordertogether');
        default:
          return 'unknown';
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    addOrder() {
      if (this.selectedItem) {
        this.isClick = true;
        setTimeout(() => {
          this.isClick = false;
        }, 5000);
        let arr = []
        const loading1 = this.$loading({
          lock: true,
          text: 'loading···',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)',
          customClass: 'custom-loading'
        });
        this.getShoppingCart.map(item => {
          if (item.check) {
            arr.push(item.id)
          }
        })
        const addressId = this.selectedItem
        this.$axios
          .post("/api/mall/order/" + this.$store.getters.getUser.id + '/' + addressId,
            arr)
          .then(res => {
            switch (res.data.code) {
              // “001”代表结算成功
              case 200:
                for (let i = 0; i < arr.length; i++) {
                  // 删除已经结算的购物车商品
                  this.deleteShoppingCart(arr[i]);
                }
                // 提示结算结果
                this.notifySucceed(this.$t('cart.ordered'));
                loading1.close();
                this.$router.push({ path: "/finishedorder" });
                break;
              default:
                // 提示失败信息
                this.notifyError(res.data.message);
                loading1.close();
            }
          })
          .catch(err => {
            return Promise.reject(err);
          });
      } else {
        this.$message({
          message: 'Please select address!!!',
          type: 'warning'
        });
      }
    }
  },
  computed: {
    ...mapGetters([
      "getCheckGoodList",
      "getShoppingCart",
      "getCheckNum",
      "getTotalPrice",
      "getCheckGoods",
    ]),
  }
}
</script>

<style scoped>
.wrap {
  width: 1280px;
  margin: 30px auto 0;
}
::-webkit-scrollbar {
  display: none;
}
::v-deep .el-breadcrumb__inner.is-link:hover {
  color: #000;
}

.bread {
  margin-bottom: 30px;
}

.tit {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 25px;
}

.lf {
  flex: 1;
}

.info {
  /* height: 199px; */
  border: 1px solid #EAEAEA;
  border-radius: 15px;
}

.info1 {
  border: 1px solid #EAEAEA;
  border-radius: 15px;
  padding-bottom: 34px;
}

.infotit {
  height: 46px;
  background-color: #EAEAEA;
  border-radius: 15px 15px 0 0;
  padding-left: 32px;
  font-size: 18px;
  font-weight: bold;
  line-height: 46px;
}

.rig {
  margin-left: 26px;
  flex: 1;
}

.infotits {
  font-size: 12px;
  color: #959595;
  margin-bottom: 6px;
}

.infodetails {
  color: #545454;
  font-size: 14px;
}

.add {
  margin-left: 10px;
  color: #4583E1;
  font-size: 12px;
  text-decoration: underline;
}

.addlist {
  width: 623px;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  padding-left: 15px;
  padding-bottom: 15px;
}

.adds {
  margin-top: 20px;
  padding-bottom: 14px;
  width: 590px;
  height: 70px;
  display: flex;
  color: #545454;
  border-bottom: 1px solid #D9D9D9;
}

.scrollable-content {
  width: 100%;
  height: 100%;
  overflow: auto;
  scrollbar-color: #959595 transparent;
  scrollbar-width: thin;
  -ms-overflow-style: none;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: #959595;
  border-radius: 6px;
}

.scrollable-content::-webkit-scrollbar-track {
  background-color: transparent;
  border: none;
}

.scrollable-content::-webkit-scrollbar-button {
  color: transparent;
}

.addnew {
  width: 178px;
  font-size: 16px;
  color: #4583E1;
  background-color: #95C0FF;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}

.save {
  width: 64px;
  height: 33px;
  font-size: 12px;
  color: #4583E1;
  background-color: #95C0FF;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}

.grayed-out {
  opacity: 0.38;
  pointer-events: none;
}

.addlist-out {
  height: 200px !important;
}

::v-deep .el-loading-spinner {
  font-size: 80px;
}

::v-deep .custom-loading .el-loading-text {
  font-size: 30px;
  font-weight: bold;
}
</style>